import { dbConnect } from "../database/dbconnect";

export default function CampaignBuilder(): JSX.Element {
    return (
        <div>
            <p>TEST123</p>
            <p>{dbConnect()}</p>
        </div>
    );
}
